/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_57_50 {
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_57_96 {
  position: absolute;
  bottom: 24px;
}

.clt_42_5 {
  height: 100vh;
  background-color: var(--default-background);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
