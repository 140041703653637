/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_24_30 {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.clt_24_132 {
  height: 100%;
  padding: 40px 8px 40px 40px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}

.clt_24_34 {
  height: 100vh;
  flex: 1;
  position: relative;
  max-width: 440px;
  margin-right: 10px;
  min-width: 275px;

  @media only screen and (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin: auto;
  }
}

.clt_24_58 {
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    padding: 0px 0px 40px 18px;
    flex: 1;
  }
}

.clt_24_1 {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 768px;
    padding-bottom: 18px;
    height: 100vh;
  }
}

.clt_24_300 {
  background-color: var(--default-background);
  height: 100%;
  width: 100%;
  min-width: fit-content;

  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}
