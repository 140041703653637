/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_21_56 {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}

.clt_21_83 {
  min-height: 88px;

  @media only screen and (max-width: 768px) {
    min-height: unset;
  }
}

.clt_17_120 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }

  & > h1 {
    @media only screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.clt_72_84 {
  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.clt_17_64 {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.clt_23_47 {
  height: 100%;
  width: 100%;
  position: relative;
}
