/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_22_55 {
  position: absolute;
  top: 0;
  right: 0;
}

.clt_22_96 {
  position: absolute;
  top: 80px;
  right: 0;
}

.clt_22_97 {
  position: absolute;
  top: 50px;
  right: 80px;
}

.clt_22_107 {
  position: relative;
  transform: rotate(45deg);
  transform-origin: top right;
  top: 125px;
}

.clt_22_282 {
  position: absolute;
  top: 0;
  right: 30px;
}

.clt_22_283 {
  position: absolute;
  top: 80px;
  right: 0;
}

.clt_22_284 {
  position: absolute;
  top: 50px;
  right: 140px;
}

.clt_22_281 {
  position: relative;
  transform: translateY(107px) rotate(45deg) translateY(12px);
  transform-origin: top right;
  top: calc(100% - 276px);
  right: -30px;
}

.clt_23_10 {
  position: absolute;
  top: 0;
  right: 0;
}

.clt_23_9 {
  position: absolute;
  top: 40px;
  right: 40px;
}

.clt_23_8 {
  position: absolute;
  top: 120px;
  right: 120px;
}

.clt_23_7 {
  position: relative;
  top: 0;
}

.clt_23_14 {
  position: absolute;
  top: 0;
  right: 0;
}

.clt_23_13 {
  position: absolute;
  top: 120px;
  right: 0;
}

.clt_23_12 {
  position: absolute;
  top: 40px;
  right: 60px;
}

.clt_23_11 {
  position: relative;
  top: calc(100% - 220px);
  right: 0;
}

.clt_22_180 {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.clt_77_119 {
  position: absolute;
  top: 0;
  right: 0;
}

.clt_77_118 {
  position: absolute;
  top: 40px;
  right: 0;
}

.clt_77_117 {
  position: absolute;
  top: 25px;
  right: 40px;
}

.clt_77_116 {
  position: relative;
  transform: rotate(45deg);
  transform-origin: top right;
  top: 40px;
  right: 15px;
}

.clt_77_78 {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
}
