/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_77_176 {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    padding: 16px;
  }
}

.clt_24_106 {
  height: 100%;
  flex: 5;
  background-image: url('https://cdn.paragonone.com/company-app/imgs/8c8fa7c6008f27326c84ce154953aaf8.jpg');
  background-repeat: no-repeat;
  background-position: -200px;
  background-size: cover;

  @media (min-width: 1800px) {
    background-position: unset;
  }

  @media only screen and (max-width: 768px) {
    background-position: center;
  }
}

.clt_24_182 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
}

.clt_24_143 {
  padding: 40px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
}

.clt_24_100 {
  height: 100%;
  flex: 3;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex: 1;
  }
}

.clt_24_99 {
  background-color: var(--default-background);
  z-index: -2;
  height: 100vh;
  width: 100%;
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}
