/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_13_4 {
  width: 20px;
  height: 20px;
}

.clt_13_15 {
  font-family: 'Open Sauce Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #222120;
}

.clt_13_3 {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
}
