/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_31_75 {
  width: 8px;
  height: 8px;
}

.clt_31_74 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
}
