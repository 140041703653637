/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */

.clt_v200_1817 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.clt_32_0 {
  width: 14px;
  height: 14px;
}

.clt_31_259 {
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
}

.clt_31_191 {
  position: absolute;
  bottom: 0;
  transform: translate(50%, 50%);
  background-color: var(--default-background);
  right: 50%;
}

.clt_54_52 {
  margin-bottom: 20px;

  @media (max-height: 899px) {
    margin-bottom: 12px;
  }
}

.clt_72_96 {
  @media only screen and (max-width: 768px) {
    width: 90vw;
  }
}

.clt_31_60 {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  margin-top: 16px;
}

.clt_31_26 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_31_3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clt_31_174 {
  margin: 0;
  padding: 24px 0 24px 0;
  position: relative;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: unset;
}

.clt_32_23 {
  margin-top: 25px;

  @media only screen and (max-width: 768px) {
    padding-right: 18px;
  }
}
